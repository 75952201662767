// Maori
export default{
    form: {
        startSurveyButton: "Kia tīmata",
        nextButton: "Panuku",
        submitAnswerButton: "Tukua te whakautu",
        continueButton: "Haere tonu",
        finishButton: "Whakaoti",
        headerPercentageCompletion: 'oti',
        headerQuestionsCompleted: 'pātai',
        headerDefaultErrorMessage: 'Aue! He hapa te raru.',
        brandingPoweredBy: 'Whakahaerehia e',
        brandingPromoWhySurvey: 'He aha te uiuinga mēnā ka taea e koe te whakamahi i <b>{product}</b>?',
        brandingPromoGetStarted: 'Tīmata i tēnei rā!',
        submissionPending: "Kei te tukua tō voiceform. Kaua e katia te matapihi pūtirotiro.",
        submissionCanCloseWindow: "Kua pai te tukunga o tō voiceform. Ka taea e koe te kati i te matapihi 👍",
        mediaAttachmentOverlayCloseButton: "Katia",
        expiredFormHeader: "Ngā mihi mō te toro mai!",
        expiredFormMessage: "Te āhua nei kua pau te wā mō tēnei uiuinga. Tēnā, whakapā atu ki te kaihanga voiceform kia mōhio ai rātou.",
        notPublishedFormHeader: "Kāore anō te voiceform kia whakaputaina!",
        notPublishedFormMessage: "Te āhua nei kāore anō tēnei uiuinga kia whakaputaina. Tēnā, whakapā atu ki te kaihanga voiceform kia mōhio ai rātou.",
        notPublishedFormRetryButton: "Ngana anō",
        errorFormHeader: "Aue!",
        errorFormMessage: "Tērā pea i hapa kē atu. Ngana anō ā tōna wā.",
        errorFormRetryButton: "Ngana anō",
        emptyFormHeader: "Ngā mihi mō te toro mai!",
        emptyFormMessage: "Aue! Te āhua nei he takoto kau tēnei puka, kāore he pātai, kāore he wāhi mō te whakakī. E whakapāha ana mō ngā whakararuraru. Tēnā, whakapā atu ki te kaihanga o tēnei puka kia whakamōhiotia rātou mō te raru. Ngā mihi mō tō mārama me tō tautoko!",

        restoreFormHeader: "Te āhua nei kei te haere tonu tō puka!",
        restoreFormMessage: "Kei te pīrangi koe ki te haere tonu i te wāhi i mutu ai koe?",
        restoreFormButton: "Haere tonu te puka",
        restoreFormButtonNew: "Tīmata anō",

        audioPermissionHeader: "Kei te pīrangi koe ki te whakautu mā te reo?",
        audioPermissionMessage: "Mā te kōrero ka tere ake, ka māmā hoki te whakautu.",
        audioPermissionAcceptButton: "Āe, e hiahia ana au",
        audioPermissionDenyButton: "Kāo, tēnā koe",
        audioPermissionErrorHeader: "Kāore te hopuoro e wātea ana",
        audioPermissionErrorMessage: "Tēnā, tirohia ngā whakaaetanga pūtirotiro kia āhei ai te uru ki te hopuoro.",
        audioPermissionErrorButton: "Āe",
        audioRecorderFailedUpload: "Kāore i taea te tuku ake i te hopunga",
        silenceDetectorError: "Kāore mātou e rongo i a koe. Tēnā, tirohia ngā tautuhinga o tō hopuoro.",
        audioRecorderButtonRecord: "Pāwhiri hei hopu",
        audioRecorderButtonRecordMore: "Hopukina anō",
        voiceResponseSelectionMessage: "Kōwhiria he huarahi hei whakautu...",
        voiceResponseSelectionOrDivider: "rānei",
        voiceResponseTextInputPlaceholder: "Tuhia te whakautu",
        voiceResponseTextInputCharacterCounterMinMessage: "Tēnā, whakauruhia he iti rawa {min} pūāhua",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} pūāhua (iti rawa {min} pūāhua)",
        voiceResponseTextInputCharacterValidationMessage: "{count} pūāhua (iti rawa {min} pūāhua)",

        // checkbox
        checkboxValidationTooFew: "Me kōwhiri koe i te iti rawa o ngā {min} kōwhiringa",
        checkboxValidationTooMany: "Me kōwhiri koe i te nuinga o ngā {max} kōwhiringa",
        checkboxNoneOfTheAboveOption: "Kāore tētahi o ēnei",
        checkboxInvalidAnswer: "Whakautu hē.",

        // datepicker
        datePickerPlaceholder: "Kōwhiria te rā",

        // dropdown
        // email
        emailLabel: "Īmēra",
        emailInvalid: "Īmēra hē.",
        // file-upload
        fileUploadPluginNameCamera: "Kāmera",
        fileUploadPluginNameCameraVideo: "Hopukina te ataata",
        fileUploadPluginScreenCast: "Hopungia te Mata",
        fileUploadVideoImportFilesDefault: 'Hopukina, tukuatu rānei tētahi ataata mā:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Kōwhiria he kōwhiringa mō te hopu ataata:',
        fileUploadVideoImportFilesNoCamera: 'Pāwhiritia te pātene hei hopu ataata',
        fileUploadVideoImportFilesNoScreenCast: 'Pāwhiritia te pātene hei hopu ataata',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Kāore he kōwhiringa e wātea ana mō te hopu ataata',

        // matrix
        matrixValidationMessage: "He tika te whakautu.",
        matrixRow: "Rārangi",
        matrixColumn: "Tīwae",
        matrixRowRequired: "Ko te rārangi {rowTitle} me whakakiia.",
        matrixRadioGroup: "Rōpū rēreti",
        matrixCheckboxGroup: "Rōpū pouaka taki",
        matrixGroupRequired: "Mō te rārangi {rowTitle}. {type} {groupTitle} me whakakiia.",
        matrixColumnRequired: "Mō te rārangi {rowTitle}. Ko te tīwae {columnTitle} me whakakiia.",
        matrixColumnInvalid: "Mō te rārangi {rowTitle}. Ko te tīwae {columnTitle} he hē.",
        matrixRequired: "Me whakakiia.",
        matrixNumericMustBeNumber: "Me tau te whakaurunga.",
        matrixNumericMustBeGreaterThenMin: "Me nui ake i te {min}.",
        matrixNumericMustBeLessThenMax: "Me iti iho i te {max}.",
        matrixNumericMustBeInteger: "Me te tauoti.",
        matrixNumericInvalidNumber: "Tau hē.",

        // name
        nameLabel: "Ingoa",
        nameInvalid: "Tēnā, whakauruhia te ingoa me te ingoa whānau.",

        // nps
        npsNotLikely: "Tino kore pea",
        npsExtremelyLikely: "Tino pea",

        // numeric input
        numericInputRequired: "Me whakakiia.",
        numericInputMustBeNumber: "Me tau te whakaurunga.",
        numericInputMustBeGreaterThenMin: "Me nui ake i te {min}.",
        numericInputMustBeLessThenMax: "Me iti iho i te {max}.",
        numericInputMustBeInteger: "Me te tauoti.",
        numericInputInvalidNumber: "Tau hē.",
        numericInputPlaceholder: "Tuhia he tau",

        // phone
        phoneInvalid: "Tau waea hē.",
        phoneCountrySelectorLabel: 'Waehere whenua',
        phoneCountrySelectorError: 'Kōwhiria he whenua',
        phoneNumberLabel: 'Tau waea',
        phoneExample: 'Tauira:',

        // boolean
        booleanYesLabel: "Āe",
        booleanNoLabel: "Kāo",

        //questionStep
        questionStepAudioQuestionLabel: "Pātai oro",

        // errors
        invalidPhoneNumber: "He hē te tau waea {phone}.",
        invalidEmail: "He hē te wāhitau īmēra {email}.",
        questionIsRequired: "Me whakautu te pātai.",
        answerIsNotValid: "He hē te whakautu.",
        unfinishedProbingDialogError: "Tēnā, whakaotia te kōrerorero.",
        cannotResumePartialResponse: "Kāore e taea te haere tonu i te whakautu wāwāhi.",
        failedToSubmitForm: "Kāore i taea te tuku i te puka. Tirohia tō hononga ipurangi, ngana anō.",

        //language picker
        searchLanguage: "Rapu i te reo",
    }
}
